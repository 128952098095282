
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_app",
      function () {
        return require("private-next-pages/_app.common.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_app"])
      });
    }
  